import { useRouter } from 'next/router'
import Cart from 'components/cart/cart'
import Icon from 'components/icon/icon'
import { cn, getRoute } from 'utils/helpers'
import styles from './header-icons.module.scss'
import useAppState from 'utils/state'

export default function HeaderIcons({ alt, hideDropdown }) {
    const { user, hasUser } = useAppState()
    const { pathname } = useRouter()

    const favoritedUrl = getRoute('favorited')
    const loginUrl = getRoute('login')
    const useFavoritedUrl = user ? favoritedUrl : `${loginUrl}?r=${favoritedUrl}`
    const profileUrl = getRoute('profile-details')
    const accountUrl = getRoute('account-dashboard')

    //
    // <Icon
    // href="/kaledos"
    // label="Kalėdos"
    // icon="christmas"
    // className={cn(styles.icon, styles.christmas, pathname === '/kaledos' && styles.iconActive)}
    // />

    // <Icon
    // href="/k/valentino-diena"
    // label="Valentino diena"
    // icon="valentine"
    // className={cn(styles.icon, styles.pulse)}
    // />

    // <Icon href="/moters-diena" label="Moters diena" icon="tulip" className={cn(styles.icon, styles.pulse)} />
    // <Icon href="/k/velykos" label="Velykos" icon="easter" className={cn(styles.icon, styles.egg)} />

    // <Icon
    //             href="/kaledos"
    //             label="Kalėdos"
    //             icon="christmas"
    //             className={cn(styles.icon, styles.christmas, pathname === '/kaledos' && styles.iconActive)}
    //         />

    return (
        <div className={cn(styles.headerActions, alt && styles.alt)}>
            <Icon href="/k/velykos" label="Velykos" icon="easter" className={cn(styles.icon, styles.egg)} />
            <Icon
                href={useFavoritedUrl}
                label="Mano norai"
                icon="heart"
                className={cn(styles.icon, pathname === favoritedUrl && styles.iconActive)}
            />
            {hasUser ? (
                <Icon
                    href={profileUrl}
                    label="Profilis"
                    icon="user"
                    className={cn(styles.icon, pathname.startsWith(profileUrl) && styles.iconActive)}
                />
            ) : (
                <Icon
                    href={loginUrl}
                    label="Prisijungimas"
                    icon="login"
                    className={cn(styles.icon, pathname === loginUrl && styles.iconActive)}
                />
            )}
            {hasUser == 2 && (
                <Icon
                    href={accountUrl}
                    label="Paskyra"
                    icon="store"
                    className={cn(styles.icon, pathname.startsWith(accountUrl) && styles.iconActive)}
                />
            )}
            <Cart pathname={pathname} hideDropdown={hideDropdown} />
        </div>
    )
}
